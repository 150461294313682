<template>
  <v-dialog
    v-if="tutorial.Ongoing"
    v-model="chatDialog"
    width="480"
    overlay-opacity="0.8"
    id="chat"
    persistent
    content-class="chat-dialog"
    transition="dialog-fade"
  >
    <div>
      <div class="d-flex justify-space-between" style="width: 100%">
        <v-img
          v-if="chats[q - 1].reaction && chats[q - 1].reaction == 'sad'"
          width="100%"
          max-width="150"
          :src="require('@/assets/tutorial/hamochi_sad.png')"
          class="mb-3"
          contain
        ></v-img>
        <v-img
          v-else-if="chats[q - 1].reaction && chats[q - 1].reaction == 'happy'"
          width="100%"
          max-width="150"
          :src="require('@/assets/tutorial/hamochi_happy.png')"
          class="mb-3"
          contain
        ></v-img>
        <v-img
          v-else
          width="100%"
          max-width="150"
          :src="require('@/assets/tutorial/chat_1.png')"
          class="mb-3"
          contain
        ></v-img>
      </div>

      <div style="position: relative">
        <span class="char-name">{{ chats[q - 1].citizen }}</span>
      </div>

      <v-card
        class="align-self-end chat-plot"
        style="width: 100%"
        @click="next(chats.length)"
      >
        <v-card-text class="text-h6 py-0 my-0">
          <v-stepper v-model="q" elevation="0" class="chat-step">
            <v-stepper-items>
              <v-stepper-content
                v-for="(chat, i) in chats"
                :key="i"
                :step="i + 1"
              >
                {{ chat.chat }}
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>

        <v-card-actions class="pt-0 bounce2">
          <v-spacer></v-spacer>
          <span style="font-size: smaller">
            {{ $t("action.clickToContinue") }} <span>▼</span> 
          </span>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    tutorial: (state) => state.tutorial.data,
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
  }),
  props: ["chats", "callback", "stopSkip"],
  data: () => ({
    q: 1,
    chatDialog: true,
  }),
  created() {
    //
  },
  mounted() {
    if (
      this.settings.audio &&
      this.settings.audio.sfx &&
      this.settings.audio.master
    ) {
      let audio = new Audio(
        require("@/assets/sounds/" + this.chats[0].sound + ".mp3")
      );
      audio.volume = this.settings.audio.sfx * this.settings.audio.master;
      audio.play();
    }
  },
  methods: {
    next(chatLength) {
      if (this.q < chatLength) {
        if (
          this.settings.audio &&
          this.settings.audio.sfx &&
          this.settings.audio.master
        ) {
          let audio = new Audio(
            require("@/assets/sounds/" + this.chats[this.q].sound + ".mp3")
          );
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }
        this.q += 1;
      } else {
        if (this.callback) {
          this.callback();
        }
        this.q = 1;
      }
    },
  },
};
</script>

<style>
.chat-dialog {
  align-self: flex-end !important;
  box-shadow: none;
  /* margin-bottom: 4px !important; */
}

.char-name {
  background: rgb(255, 246, 152) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.2) 0px -1px 0px inset !important;
  border-radius: 10px !important;
  top: 0;
  left: 20px;
  position: absolute;
  width: fit-content;
  z-index: 9999;
  padding: 5px 20px;
}

.chat-plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  padding-top: 30px;
  cursor: pointer;
}

.chat-plot:hover {
  background: rgb(240, 240, 240) !important;
}

.chat-step {
  background: transparent !important;
}

.bounce2 {
  animation: bounce2 2s ease infinite !important;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-5px);}
	60% {transform: translateY(-2px);}
}
</style>